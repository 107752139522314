:root {
  --primary: rgb(27, 27, 29);
  --secondary: rgb(42, 42, 44);
  --secondary-blur: rgba(42, 42, 44, 0.5);
  --tertiary: rgb(52, 52, 54);
  --quaternary:  rgb(62, 62, 64);
  --quaternary-blur:  rgb(62, 62, 64,0.4);
  --quinary: rgb(72, 72, 74);

  --primary-blue: rgb(37, 137, 255);

  --primary-purple: #714dff;
  --secondary-purple: rgb(124, 97, 255);
  --tertiary-purple: rgb(145, 94, 255);
  --quaternary-purple: #9c83ff;

  --primary-yellow: #fff759;

  --primary-pink: #e151ff;

  --primary-red: red;
  
  --primary-green: #adff2f;

  --primary-text: #c7c7c7;
  --secondary-text: #a9a9a9;
  --tertiary-text: #909090;
}
