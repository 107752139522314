@import url("../../App.css");

@media screen and (min-width: 501px) {
    

.blurrygradient_1 {
    position: fixed;
    width: 20vw;
    height: 20vh; /* Adjust height as needed */
    z-index: 1;
    left: 0%;
    top: 100%;
    transform: translate(-50%, -50%);
    filter: blur(100px); /* Adjust blur intensity as needed */
    border-radius: 15px; /* Optional: Adds rounded corners */
    animation: colorfade1_home 5s infinite;
  }
  
  @keyframes colorfade1_home {
    0%,
    100% {
      background-color: var(--primary-yellow);
      width: 20vw;
      height: 20vh; /* Adjust height as needed */
      opacity: 0.3;
    }
    50% {
      background-color: var(--primary-pink);
      width: 30vw;
      height: 30vh; /* Adjust height as needed */
      opacity: 0.6;
    }
  }
  
  .blurrygradient_2 {
    position: fixed;
    z-index: 1;
    right: -15%;
    top: 50%;
    /* transform: translate(-50%, -50%); */
    filter: blur(100px); /* Adjust blur intensity as needed */
    border-radius: 15px; /* Optional: Adds rounded corners */
    opacity: 0.3;
    animation: colorfade2_home 5s infinite;
  }
  
  @keyframes colorfade2_home {
    0%,
    100% {
      background-color: var(--tertiary-purple);
      width: 20vw;
      height: 50vh; /* Adjust height as needed */
    }
    50% {
      background-color: var(--primary-purple);
      width: 30vw;
      height: 50vh; /* Adjust height as needed */
    }
  }
  
  .pagetitle_home {
    color: white;
    font-weight: 900;
    font-size: 56px;
    z-index: 2;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    white-space: nowrap;
  }
  
  .secondarytitle_home {
      white-space: nowrap;
    color: var(--rainbow-text);
    font-weight: 400;
    font-size: 32px;
    z-index: 2;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
    position: fixed;
  
    display: inline-block; /* or block, as needed */
    background: linear-gradient(
      50deg,
      var(--primary-purple),
      var(--secondary-purple) 31.28%,
      var(--primary-pink) 77.97%,
      var(--primary-yellow) 95.64%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

}



@media screen and (max-width: 500px) {

    

.blurrygradient_1 {
    position: fixed;
    width: 20vw;
    height: 20vh; /* Adjust height as needed */
    z-index: 1;
    left: 0%;
    top: 100%;
    transform: translate(-50%, -50%);
    filter: blur(100px); /* Adjust blur intensity as needed */
    border-radius: 15px; /* Optional: Adds rounded corners */
    animation: colorfade1_home 5s infinite;
  }
  
  @keyframes colorfade1_home {
    0%,
    100% {
      background-color: var(--primary-yellow);
      width: 20vw;
      height: 20vh; /* Adjust height as needed */
      opacity: 0.5;
    }
    50% {
      background-color: var(--primary-pink);
      width: 30vw;
      height: 30vh; /* Adjust height as needed */
      opacity: 0.8;
    }
  }
  
  .blurrygradient_2 {
    position: fixed;
    z-index: 1;
    right: -15%;
    top: 30%;
    /* transform: translate(-50%, -50%); */
    filter: blur(100px); /* Adjust blur intensity as needed */
    border-radius: 15px; /* Optional: Adds rounded corners */
    opacity: 0.5;
    animation: colorfade2_home 5s infinite;
  }
  
  @keyframes colorfade2_home {
    0%,
    100% {
      background-color: var(--tertiary-purple);
      width: 20vw;
      height: 50vh; /* Adjust height as needed */
    }
    50% {
      background-color: var(--primary-purple);
      width: 30vw;
      height: 50vh; /* Adjust height as needed */
    }
  }
  
  .pagetitle_home {
    color: white;
    font-weight: 900;
    font-size: 48px;
    z-index: 2;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    white-space: nowrap;
  }
  
  .secondarytitle_home {
      white-space: nowrap;
    color: var(--rainbow-text);
    font-weight: 400;
    font-size: 24px;
    z-index: 2;
    left: 50%;
    top: 57%;
    transform: translate(-50%, -50%);
    position: fixed;
  
    display: inline-block; /* or block, as needed */
    background: linear-gradient(
      50deg,
      var(--primary-purple),
      var(--secondary-purple) 31.28%,
      var(--primary-pink) 77.97%,
      var(--primary-yellow) 95.64%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

}
