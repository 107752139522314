@import url("../../App.css");

@media screen and (min-width: 751px) {
  .blurrygradient_experience {
    position: fixed;
    z-index: 4;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    filter: blur(100px);
    /* Adjust blur intensity as needed */
    border-radius: 15px;
    /* Optional: Adds rounded corners */
    opacity: 0.1;
    animation: colorFade_experience 5s infinite;
    z-index: 2;
  }

  @keyframes colorFade_experience {
    0% {
      background-color: var(--quaternary-purple);
      width: 80vw;
      height: 50vh;
    }

    25% {
      background-color: var(--primary-pink);
      width: 70vw;
      height: 40vh;
      /* Adjust height as needed */
    }

    50% {
      background-color: var(--primary-purple);
      width: 80vw;
      height: 40vh;
      /* Adjust height as needed */
    }

    100% {
      background-color: var(--quaternary-purple);
      width: 70vw;
      height: 50vh;
      /* Adjust height as needed */
    }
  }

  #experience_master_div {
    /* background-color: var(--secondary-blur); */
    width: 90%;
    height: auto;
    /* min-height: calc(100vh - 76px); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 50%;
    top: 76px;
    transform: translate(-50%, 0%);
    position: absolute;
    z-index: 1;
    align-items: center;
    padding-bottom: 70px;
    /* background-color: orange; */
  }

  .black_sun_and_text_div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 300px;
    z-index: 0;
    top: 70px;
  }

  .black_sun_image {
    animation: rotateImage 40s infinite;
    position: absolute;
    top: 45%;
    left: 50%;
    width: 300px;
    height: auto;
    user-select: none;
  }

  .black_sun_text {
    font-size: 72px;
    font-weight: 900;
    color: white;
    position: absolute;
    top: 57%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    white-space: nowrap;
  }

  .black_sun_blur {
    height: 270px;
    width: 350px;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1; /* Ensure text is above the image */
    /* background-color: red; */
    background-image: linear-gradient(transparent, var(--primary) 45%);
  }

  @keyframes rotateImage {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  .experience_headers {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: var(--primary-text);
    margin-top: 40px;
    margin-bottom: 5px;
    z-index: 3;
    position: relative;
  }

  .experience_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: row;
    position: relative;
    z-index: 5;
  }

  .experience_picture_div_cloud {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary);
    margin: 0px 20px;
    width: 250px;
    height: 125px;
    border-radius: 10px;

    position: relative;
    z-index: 100;
  }

  .experience_picture {
    width: 80px;
    height: auto;
    margin-bottom: auto;
  }

  #digitaloceanpic {
    margin-bottom: 0;
  }

  #googlecloudpic {
    height: 75px;
    width: auto;
    margin-bottom: 0;
  }

  .experience_picture_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary);
    height: 150px;
    width: 150px;
    border-radius: 10px;
    margin: 0px 20px;
    padding-bottom: 10px;
    padding-top: 20px;

    position: relative;
    z-index: 100;
  }

  .experience_card_text {
    color: var(--tertiary-text);
  }

  #djangoimage {
    width: 70px;
  }

  .experience_container_backend {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 150px);
    grid-template-rows: repeat(1, 150px);
    gap: 20px;
    justify-content: center;
  }

  .experience_company_worked_for_grid{
    display: grid;
    grid-template-columns: repeat(2, 250px);
    grid-template-rows: repeat(1, 1fr);
    gap: 20px;
    position: relative;
    z-index: 5;
    justify-content: center;
  }

  .experience_company_image_and_linkedin_container {
    background-color: var(--secondary);
    width: 250px;
    height: 125px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    padding-bottom: 10px;
    padding-left: 10px;
    cursor: pointer;
  }

  .companylogo_experience_worked_for{
    height: 60px;
    width: auto;
    user-select: none;
  }

  #panacealogicslogo{
    height: 50px;
  }

  .experience_linkedin_and_compapany_name_worked_for_div{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .experience_linkedin_company_worked_for_logo{
    font-size: 16px;
    height: auto;
    opacity: 0.4;
    color: var(--primary-text);
  }

  .experience_worked_for_company_name{
    color: var(--tertiary-text);
    position: relative;
    top: 4px;
    margin-left: 5px;
    opacity: 0.5;
  }

  .experience_role_and_responsibilities_master{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: var(--secondary);
    padding: 20px;
    width: 100%;
    max-width: 900px;
    margin-bottom: 20px;
  }

  .experience_role_and_responsibilities_header{
    display: flex;
    flex-direction: row;
  }

  .experience_role_and_responsibilities_header_job_title{
    margin-right: auto;
    color: var(--tertiary-text);
  }

  .experience_role_and_responsibilitie_image{
    height: 40px;
    width: auto;
  }

  .experience_role_and_responsibilities_body{
    display: flex;
    flex-direction: row;
  }

  .experience_role_and_responsibilities_body_timeline{
    display: flex;
    flex-direction: column;
    margin-right: 15px;
  }

  .experience_role_and_responsibilities_body_lists{
    display: flex;
    flex-direction: column;
  }

  .experience_role_and_responsibilities_body_lists_title{
    font-size: 18px;
    color:white;
    font-weight: 700;
  }

  .experience_role_and_responsibilities_body_lists_li{
    color: var(--primary-text);
    font-size: 18px;
  }

  .experience_role_and_responsibilities_timeline_ending_span{
    color: var(--secondary-purple);
    white-space: nowrap;
    font-weight: 700;
    font-size: 18px;
  }

  .experience_role_and_responsibilities_timeline_hr{
    width: 2px;
    height: 100%;
    /* background: var(--primary-pink); */
    background: linear-gradient(to bottom, var(--primary-purple), var(--primary-pink));
    border: none;
  }

  .experience_role_and_responsibilities_timeline_starting_span{
    color: var(--primary-pink);
    white-space: nowrap;
    font-weight: 700;
    font-size: 18px;
  }

  #panacealogics_image_experience_role_and_responsibilities{
    width: auto;
    height: 35px;
  }

  .projects_grid_experience{
    display: grid;
    width: 100%;
    max-width: 850px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    height: auto;
    gap: 20px;
    position: relative;
    z-index: 5;
    margin-top: 10px;
  }

  .projects_grid_experience_item{
    width: 100% !important;
    height: 150px !important;
  }

  .experience_role_and_responsibilities_body_lists_ul{
    margin-top: 5px;
  }
}

@media screen and (max-width: 750px) {
  #experience_master_div {
    /* background-color: var(--secondary-blur); */
    width: 90%;
    height: auto;
    /* min-height: calc(100vh - 76px); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 50%;
    top: 76px;
    transform: translate(-50%, 0%);
    position: absolute;
    z-index: 1;
    padding-bottom: 70px;
    /* background-color: orange; */
  }

  .black_sun_and_text_div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 250px;
    z-index: 0;
    top: 70px;
  }

  .black_sun_image {
    animation: rotateImage 40s infinite;
    position: absolute;
    top: 45%;
    left: 50%;
    width: 250px;
    height: auto;
    user-select: none;
  }

  .black_sun_text {
    overflow: hidden;
    font-size: 40px;
    font-weight: 900;
    color: white;
    position: absolute;
    top: 57%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    white-space: nowrap;
    /* width: 300px; */
  }

  .black_sun_blur {
    height: 220px;
    width: 270px;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1; /* Ensure text is above the image */
    /* background-color: red; */
    background-image: linear-gradient(transparent, var(--primary) 45%);
  }

  @keyframes rotateImage {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  .experience_headers {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: var(--primary-text);
    margin-top: 30px;
    margin-bottom: 7px;
    z-index: 3;
    position: relative;
  }

  .experience_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: row;
  }

  .experience_picture_div_cloud {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary);
    margin: 0px 10px;
    width: 150px;
    height: 100px;
    border-radius: 10px;

    position: relative;
    z-index: 100;
  }

  .experience_picture {
    width: 50px;
    height: auto;
    margin-bottom: auto;
  }

  #digitaloceanpic {
    margin-bottom: 0;
    width: auto;
    height: 60px;
  }

  #googlecloudpic {
    height: 60px;
    width: auto;
    margin-bottom: 0;
  }

  .experience_picture_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary);
    height: 100px;
    width: 100px;
    border-radius: 10px;
    margin: 0px 10px;
    padding-bottom: 5px;
    padding-top: 15px;

    position: relative;
    z-index: 100;
  }

  .experience_card_text {
    color: var(--tertiary-text);
  }

  #djangoimage {
    width: 40px;
  }

  .experience_container_backend {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 100px);
    grid-template-rows: repeat(2, 100px);
    gap: 20px;
    justify-content: center;
    position: relative;
    left: -10px;
  }

  .blurrygradient_experience {
    position: fixed;
    z-index: 4;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    filter: blur(100px);
    /* Adjust blur intensity as needed */
    border-radius: 15px;
    /* Optional: Adds rounded corners */
    opacity: 0.1;
    animation: colorFade_experience 5s infinite;
    z-index: 2;
  }

  @keyframes colorFade_experience {
    0% {
      background-color: var(--quaternary-purple);
      width: 80vw;
      height: 50vh;
    }

    25% {
      background-color: var(--primary-pink);
      width: 70vw;
      height: 40vh;
      /* Adjust height as needed */
    }

    50% {
      background-color: var(--primary-purple);
      width: 80vw;
      height: 40vh;
      /* Adjust height as needed */
    }

    100% {
      background-color: var(--quaternary-purple);
      width: 70vw;
      height: 50vh;
      /* Adjust height as needed */
    }
  }

  .experience_company_worked_for_grid{
    display: grid;
    grid-template-columns: repeat(1, 250px);
    grid-template-rows: repeat(1, 1fr);
    gap: 20px;
    justify-content: center;
    position: relative;
    z-index: 5;
  }

  .experience_company_image_and_linkedin_container {
    background-color: var(--secondary);
    width: 250px;
    height: 125px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    padding-bottom: 10px;
    padding-left: 10px;
    cursor: pointer;
  }

  .companylogo_experience_worked_for{
    height: 60px;
    width: auto;
    user-select: none;
  }

  .experience_linkedin_and_compapany_name_worked_for_div{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .experience_linkedin_company_worked_for_logo{
    font-size: 16px;
    height: auto;
    opacity: 0.4;
    color: var(--primary-text);
  }

  .experience_worked_for_company_name{
    color: var(--tertiary-text);
    position: relative;
    top: 4px;
    margin-left: 5px;
    opacity: 0.5;
  }

  #panacealogicslogo{
    height: 50px;
  }

  .experience_role_and_responsibilities_master{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: var(--secondary);
    padding: 20px;
    margin-bottom: 20px;
  }

  .experience_role_and_responsibilities_header{
    display: flex;
    flex-direction: row;
  }

  .experience_role_and_responsibilities_header_job_title{
    margin-right: auto;
    color: var(--tertiary-text);
  }

  .experience_role_and_responsibilitie_image{
    height: 30px;
    width: auto;
  }

  .experience_role_and_responsibilities_body{
    display: flex;
    flex-direction: column;
  }

  .experience_role_and_responsibilities_body_timeline{
    display: flex;
    flex-direction: row-reverse;
    margin-right: 15px;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
  }

  .experience_role_and_responsibilities_body_lists{
    display: flex;
    flex-direction: column;
  }

  .experience_role_and_responsibilities_body_lists_title{
    font-size: 16px;
    color:white;
    font-weight: 700;
  }

  .experience_role_and_responsibilities_body_lists_li{
    color: var(--primary-text);
    font-size: 16px;
  }

  .experience_role_and_responsibilities_timeline_ending_span{
    color: var(--secondary-purple);
    white-space: nowrap;
    font-weight: 700;
    margin-left: 5px;
  }

  .experience_role_and_responsibilities_timeline_hr{
    width: 100%;
    height: 2px;
    /* background: var(--primary-pink); */
    background: linear-gradient(to left, var(--primary-purple), var(--primary-pink));
    border: none;
  }

  .experience_role_and_responsibilities_timeline_starting_span{
    color: var(--primary-pink);
    white-space: nowrap;
    font-weight: 700;
    margin-right: 5px;
  }

  #panacealogics_image_experience_role_and_responsibilities{
    width: auto;
    height: 25px;
  }

  .projects_grid_experience{
    display: grid;
    width: 100%;
    max-width: 850px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    height: auto;
    gap: 20px;
    position: relative;
    z-index: 5;
    margin-top: 10px;
  }

  .projects_grid_experience_item{
    width: 100% !important;
    height: 150px !important;
  }

  .experience_role_and_responsibilities_body_lists_ul{
    margin-top: 5px;
  }
}
