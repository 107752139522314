@import url("../../App.css");

@media screen and (min-width: 501px) {
  #page_center_contact {
    color: white;
    font-weight: 900;
    font-size: 56px;
    z-index: 2;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .blurrygradient_1_contact {
    position: fixed;
    width: 20vw;
    height: 20vh; /* Adjust height as needed */
    z-index: 1;
    left: 0%;
    top: 70%;
    transform: translate(-50%, -50%);
    filter: blur(100px); /* Adjust blur intensity as needed */
    border-radius: 15px; /* Optional: Adds rounded corners */
    animation: colorfade1_contact 5s infinite;
  }

  #copied_contact_span {
    position: fixed;
    color: var(--primary-green);
    z-index: 2;
    left: 50%;
    top: 75%;
    transform: translate(-50%, -50%);
  }

  @keyframes colorfade1_contact {
    0%,
    100% {
      background-color: var(--primary-blue);
      width: 30vw;
      height: 30vh; /* Adjust height as needed */
      opacity: 0.3;
    }
    50% {
      background-color: var(--quaternary-purple);
      width: 30vw;
      height: 30vh; /* Adjust height as needed */
      opacity: 0.6;
    }
  }

  .blurrygradient_2_contact {
    position: fixed;
    z-index: 1;
    right: -15%;
    top: 50%;
    /* transform: translate(-50%, -50%); */
    filter: blur(100px); /* Adjust blur intensity as needed */
    border-radius: 15px; /* Optional: Adds rounded corners */
    opacity: 0.3;
    animation: colorfade2_contact 5s infinite;
  }

  @keyframes colorfade2_contact {
    0%,
    100% {
      background-color: var(--primary-red);
      width: 20vw;
      height: 50vh; /* Adjust height as needed */
    }
    50% {
      background-color: var(--primary-green);
      width: 30vw;
      height: 50vh; /* Adjust height as needed */
    }
  }

  .pagetitle_contact {
    color: white;
    font-weight: 900;
    font-size: 56px;
    white-space: nowrap;
  }

  .secondarytitle_contact {
    white-space: nowrap;
    color: var(--rainbow-text);
    font-weight: 400;
    font-size: 32px;

    display: inline-block; /* or block, as needed */
    background: linear-gradient(
      50deg,
      var(--primary-purple),
      var(--secondary-purple) 31.28%,
      var(--primary-pink) 77.97%,
      var(--primary-yellow) 95.64%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .contact_icon_container_grid {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 2 columns */
    grid-template-rows: repeat(2, 1fr); /* 2 rows */
    gap: 15px; /* Optional gap between grid items */
  }

  .contact_icon_container {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary);
    padding: 10px;
    border-radius: 10px;
  }

  .contact_icon {
    font-size: 32px;
    color: var(--primary-text);
  }

  .contact_icon_check {
    font-size: 32px;
    color: var(--primary-green);
  }
}

@media screen and (max-width: 500px) {
  .blurrygradient_1_contact {
    position: fixed;
    width: 20vw;
    height: 20vh; /* Adjust height as needed */
    z-index: 1;
    left: 0%;
    top: 100%;
    transform: translate(-50%, -50%);
    filter: blur(100px); /* Adjust blur intensity as needed */
    border-radius: 15px; /* Optional: Adds rounded corners */
    animation: colorfade1_contact 5s infinite;
  }

  @keyframes colorfade1_contact {
    0%,
    100% {
      background-color: var(--primary-blue);
      width: 30vw;
      height: 30vh; /* Adjust height as needed */
      opacity: 0.4;
    }
    50% {
      background-color: var(--quaternary-purple);
      width: 30vw;
      height: 30vh; /* Adjust height as needed */
      opacity: 0.6;
    }
  }

  .blurrygradient_2_contact {
    position: fixed;
    z-index: 1;
    right: -30%;
    top: 30%;
    /* transform: translate(-50%, -50%); */
    filter: blur(100px); /* Adjust blur intensity as needed */
    border-radius: 15px; /* Optional: Adds rounded corners */
    opacity: 0.5;
    animation: colorfade2_contact 5s infinite;
  }

  @keyframes colorfade2_contact {
    0%,
    100% {
      background-color: var(--primary-red);
      width: 20vw;
      height: 50vh; /* Adjust height as needed */
    }
    50% {
      background-color: var(--primary-green);
      width: 30vw;
      height: 50vh; /* Adjust height as needed */
    }
  }

  .pagetitle_contact {
    color: white;
    font-weight: 900;
    font-size: 48px;
    white-space: nowrap;
  }

  .secondarytitle_contact {
    white-space: nowrap;
    color: var(--rainbow-text);
    font-weight: 400;
    font-size: 24px;

    display: inline-block; /* or block, as needed */
    background: linear-gradient(
      50deg,
      var(--primary-purple),
      var(--secondary-purple) 31.28%,
      var(--primary-pink) 77.97%,
      var(--primary-yellow) 95.64%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  #page_center_contact {
    color: white;
    font-weight: 900;
    font-size: 56px;
    z-index: 2;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contact_icon_container_grid {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 2 columns */
    grid-template-rows: repeat(2, 1fr); /* 2 rows */
    gap: 15px; /* Optional gap between grid items */
  }

  .contact_icon_container {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--tertiary);
    padding: 10px;
    border-radius: 10px;
  }

  .contact_icon {
    font-size: 24px;
    color: var(--primary-text);
  }

  .contact_icon_check {
    font-size: 24px;
    color: var(--primary-green);
  }

  #copied_contact_span {
    white-space: nowrap;
    position: fixed;
    color: var(--primary-green);
    z-index: 2;
    left: 50%;
    top: 73%;
    transform: translate(-50%, -50%);
  }
}
