@import url('../../App.css');

body::-webkit-scrollbar {
    width: 12px; /* Adjust the width as needed */
  }
  
  body::-webkit-scrollbar-track {
    background-color: var(--primary); /* Color of the scrollbar track */
  }
  
  body::-webkit-scrollbar-thumb {
    background-color: var(--quinary); /* Color of the scrollbar thumb */
  }


@media screen and (min-width: 601px) {
    #navbar_master{
        position: fixed;
        top: 20px;
        width: 500px;
        display: flex;
        flex-direction: row;
        background-color: var(--quaternary-blur);
        height: 56px;
        left: 50%;
        align-items: center;
        transform: translate(-50%, 0%);
        border-radius: 1000px;
        padding: 0px 14px;
        z-index: 2;
        backdrop-filter: blur(30px);
        border: 1px solid rgb(255,255,255,0.1);
    }
    
    #color_bubble{
        margin-right: auto;
        height: 35px;
        width: 35px;
        border-radius: 1000px;
        background-color: var(--primary-purple);
        animation: colorFadeBubble 5s infinite;
    }

    @keyframes colorFadeBubble {
        0% {
            background-color: var(--primary-purple);
        }
        25% {
            background-color: var(--primary-pink);
        }
        50% {
          background-color: var(--primary-blue);
        }

        100% {
            background-color: var(--primary-purple);
          }
      }
    
    * {
        font-family: "Source Sans 3", sans-serif;
        box-sizing: border-box;
    }
    
    body {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        background-color: black;
    }
    
    #starsbackground_div {
        width: 100vw;
        height: 100vh; 
        overflow: hidden;
        position: fixed;
        z-index: 0;
        background-color: var(--primary);
        /* background: linear-gradient(rgb(27,27,29) 0%, white 90%); */
    }
    
    #starsbackground_image {
        width: 100vw; 
        height: 100vh; 
        object-fit: cover; 
        position: absolute;
        animation: opacityFade 3s infinite; 
    }
    
    @keyframes opacityFade {
        0%, 100% {
            opacity: 0.3;
        }
        50% {
            opacity: 1;
        }
    }
    
    .navbar_links{
        text-decoration: none;
        color: var(--secondary-text);
        margin: 0px 20px;
        font-weight: 400;
        font-size: 18px;
    }
    
}



@media screen and (max-width: 600px) {
    #navbar_master{
        position: fixed;
        top: 20px;
        width: 90%;
        display: flex;
        flex-direction: row;
        background-color: var(--quaternary-blur);
        height: 56px;
        left: 50%;
        align-items: center;
        transform: translate(-50%, 0%);
        border-radius: 1000px;
        padding: 0px 20px;
        z-index: 2;
        justify-content: space-between;
        backdrop-filter: blur(30px);
        border: 1px solid rgb(255,255,255,0.1);
    }
    
    #color_bubble{
        display: none;
        margin-right: auto;
        height: 30px;
        width: 30px;
        border-radius: 1000px;
        background-color: var(--primary-purple);
        animation: colorFadeBubble 5s infinite;
    }

    @keyframes colorFadeBubble {
        0% {
            background-color: var(--primary-purple);
        }
        25% {
            background-color: var(--primary-pink);
        }
        50% {
          background-color: var(--primary-blue);
        }

        100% {
            background-color: var(--primary-purple);
          }
      }
    
    * {
        box-sizing: border-box;
        font-family: "Source Sans 3", sans-serif;
    }
    
    body {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        background-color: black;
    }
    
    #starsbackground_div {
        width: 100vw;
        height: 100vh; 
        overflow: hidden;
        position: fixed;
        z-index: 0;
        background-color: var(--primary);
        /* background: linear-gradient(rgb(27,27,29) 0%, white 90%); */
    }
    
    #starsbackground_image {
        width: 100vw; 
        height: 100vh; 
        object-fit: cover; 
        position: absolute;
        animation: opacityFade 3s infinite; 
    }
    
    @keyframes opacityFade {
        0%, 100% {
            opacity: 0.3;
        }
        50% {
            opacity: 1;
        }
    }
    
    .navbar_links{
        text-decoration: none;
        color: var(--secondary-text);
        /* margin: 0px 15px; */
        font-weight: 400;
        font-size: 16px;
    }
    
}
